/**
 * Page-level constraining and wrapping elements.
 *
 * @package  generator-mh-boilerplate
 * @author   Martin Herweg <info@martinherweg.de>

 */

/*
|--------------------------------------------------------------------------
| _objects.wrapper.scss
|--------------------------------------------------------------------------
*/


.o-wrapper {
	max-width: $wrapper-outer;
	min-height: 100%;
	margin-right: auto;
	margin-left: auto;
	background: $color-wrapper-bg;

	@include media('>wrapper') {
		border-left: 1px solid $color-wrapper-border;
		border-right: 1px solid $color-wrapper-border;
	}
}

.o-wrapper-inner {
	@include gutter(1);
	@include media('>desktop') {
		@include gutter(4);
	}

	max-width: $wrapper-inner;
	margin-right: auto;
	margin-left: auto;
}
