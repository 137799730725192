@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: local('Montserrat Light'), local('Montserrat-Light'), url(../Fonts/Montserrat-normal-300.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Montserrat Regular'), local('Montserrat-Regular'), url(../Fonts/Montserrat-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(../Fonts/Montserrat-normal-600.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@if ($font-primary == Montserrat) {
	$font-type-primary: sans-serif;
}
@if ($font-secondary == Montserrat) {
	$font-type-secondary: sans-serif;
}
