$social-color				: #fff !default;
$social-background-hover	: $color-text !default;

.c-social {

	&__icon {
		display: inline-block;
		width: 40px;
		height: 40px;
		padding: 8px;
		stroke-width: 0;
		stroke: currentColor;
		fill: currentColor;
		color: $social-color;

		&:hover {
			background: $social-background-hover;
		}

		&--facebook {
			background: $bc-facebook;
		}
		&--instagram {
			background: $bc-instagram;
		}
		&--twitter {
			background: $bc-twitter;
		}
		&--youtube {
			background: $bc-youtube;
		}
		&--xing {
			background: $bc-xing;
		}
		&--linkedin {
			background: $bc-linkedin;
		}
		&--printerest {
			background: $bc-youtube;
		}
	}
}
