
$accordion-title-fontsize: $type-mid !default;
$accordion-title-uppercase: true !default;

$accordion-l1-title-background: color('background') !default;
$accordion-l1-title-background-open: $color-primary !default;
$accordion-l1-title-color: $color-text !default;
$accordion-l1-title-color-open: #fff !default;
$accordion-l1-arrow-color: $color-text !default;
$accordion-l1-arrow-color-open: #fff !default;

$accordion-l2-title-background: transparent !default;
$accordion-l2-title-background-open: transparent !default;
$accordion-l2-title-color: $color-text !default;
$accordion-l2-title-color-open: $color-primary !default;
$accordion-l2-arrow-color: $color-text !default;
$accordion-l2-arrow-color-open: $color-primary !default;
$accordion-l2-border-color: $color-primary !default;

.c-accordion {
	@extend .o-wrapper-inner;
	margin-bottom: $element-margin-bottom-mobile;

	@include media('>grid') {
		margin-bottom: $element-margin-bottom;
	}

	&__title {
		position: relative;
		cursor: pointer;
		@include fluid-type(font-size, 320px, 1366px, 20px, $accordion-title-fontsize);
		padding: 16px 48px 16px 32px;
		transition: transform 0.5s;

		@if $accordion-title-uppercase == true {
			text-transform: uppercase;
		}

	}

	&[data-layout="1"] {
		.c-accordion__title {
			color: $accordion-l1-title-color;
			background: $accordion-l1-title-background;
			margin-bottom: 32px;

			&--open {
				color: $accordion-l1-title-color-open;
				background: $accordion-l1-title-background-open;

				.c-accordion__arrow {
					transform: rotate(90deg);
					fill: $accordion-l1-arrow-color-open;
				}
			}
		}
		.c-accordion__arrow {
			fill: $accordion-l1-arrow-color;
		}
	}

	&[data-layout="2"] {
		.c-accordion__item {
			border-top: 1px solid $accordion-l2-border-color;

			&:last-of-type {
				border-bottom: 1px solid $accordion-l2-border-color;
			}
		}
		.c-accordion__title {
			color: $accordion-l2-title-color;
			background: $accordion-l2-title-background;

			&--open {
				color: $accordion-l2-title-color-open;
				background: $accordion-l2-title-background-open;

				.c-accordion__arrow {
					transform: rotate(90deg);
					fill: $accordion-l2-arrow-color-open;
				}
			}
		}
		.c-accordion__arrow {
			fill: $accordion-l2-arrow-color;
		}
	}

	&__arrow {
		position: absolute;
		top: 0;
		right: 16px;
		width: 40px;
		height: 100%;
		transition: transform 0.5s;
	}

	svg {
		path {
			fill: inherit;
		}
	}


	&__content {
		display: none;
		padding: 12px 32px;
	}
}
