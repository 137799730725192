/*
* brandcab frontend
* version 1.0
* copyright by Rene Kreupl
*/

// Client Information
// Panzer
// Projectnumber: 00001

// Colors
$color-bg						: #fff;
$color-wrapper-border			: #fff;
$color-wrapper-bg				: #fff;

$color-primary: #882735;
$color-text: #54595d;

// Font
$font-primary: "Montserrat";

// Fontsizes
$base-font-weight				: 300;
$base-font-weight-bold			: 600;
$base-font-size					: 16px;
$base-line-height				: 28px;
$type-largest					: 40px;
//$type-large						: 28px;
//$teaserText-fontwidth			: 20px;
$navi-font-size					: 16px;

// Wrapper Sizes
$wrapper-inner:		1024px;

// Modules
$logo-width: 250px;
$logo-width-desktop: 280px;
$logo-overlay: false;

// Emotion
$emotion-overlay-color: #fff;
$emotion-overlay-background: rgba(92,73,75,0.5);
$emotion-overlay-background-mobile: rgba(92,73,75,1);
$emotion-font-headline: 40px;

// Button
$button-color-background	: $color-primary;
$button-color-border		: $color-primary;

// Footer
$footer-color-base: #505657;
$footer-color-meta: $color-primary;
$footer-color-font: #fff;

$imagetextcolor-background: #f6f4f4;

$gallery-overlay-background: rgba(247,243,224,0.5);
$gallery-overlay-background-hover: rgba(247,243,224,1);

/* +++ no changes -> import base +++ */
@import "../main";
@import "../_____fonts/font.Montserrat";
