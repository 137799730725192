
$fwImage-color-overlay: rgba(0,0,0,0.4) !default;
$fwImage-color-overlay-mobile: $color-primary !default;
$fwImage-color: #fff !default;

.c-fwImage {
	position: relative;
	margin-bottom: $element-margin-bottom-mobile;

	@include media('>grid') {
		margin-bottom: $element-margin-bottom;
	}

	&__overlay {
		background: $fwImage-color-overlay-mobile;
		text-align: center;

		@include media('>grid') {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $fwImage-color-overlay;
		}
	}
	&__headline {
		@include gutter();
		color: $fwImage-color;
		@include fluid-type(font-size, 320px, 1366px, 30px, $type-largest);
		font-family: $font-family-headings;
		font-weight: $font-weight-headings;
		line-height: $line-height-headings;
		text-rendering: optimizelegibility; // Fix the character spacing for headings

		@include media('>grid') {
			@include vertical-align();
		}
	}
}
