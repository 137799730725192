
$navi-color: $color-text !default;
$navi-color-hover: $color-primary !default;
$navi-color-active: $color-primary !default;
$navi-active-font-weight: 500 !default;
$navi-mobile-background: #fff !default;
$navi-font-size: 18px !default;

.c-navigation {
	@extend %list-reset;
	display: none;

	@include media('<640px') {
		position: absolute;
		z-index: 999;
		width: calc(100% - #{$app-gutter-width * 2});
		background: $navi-mobile-background;
	}

	@include media('>900px') {
		display: inline-block;
		background: none;
	}

	&__item {
		position: relative;
		text-align: center;

		@include media('>900px') {
			display: inline-block;
			text-align: left;
		}
	}

	&__item-level1 {
		display: block;
		padding: 18px 0;
		position: relative;
		text-transform: uppercase;
		font-size: $navi-font-size;

		@include media('>900px') {
			margin-left: 25px;
		}

		&:link, &:visited {
			color: $navi-color;
		}

		&:hover, &.hover {
			color: $navi-color-hover;
		}
		&--active:link, &--active:visited {
			color: $navi-color-active;
			font-weight: $navi-active-font-weight;
		}

	}

}
