.c-listing {
	@extend .o-wrapper-inner;
	margin-bottom: $element-margin-bottom-mobile;

	@include media('>grid') {
		margin-bottom: $element-margin-bottom;
	}

	display: flex;
	flex-wrap: wrap;

	&__item {
		position: relative;
		flex: 1 0 auto;
		max-width: 100%;
		margin-bottom: $app-gutter-width;

		@include media('>500px') {
			max-width: calc(50% - 8px);
			margin-right: $app-gutter-width;

			@include each(2) {
				margin-right: 0;
			}
		}

		&[data-modal="1"] {
			cursor: pointer;
		}
	}

	&[data-cols="2"] .c-listing__item {
		@include media('>grid') {
			max-width: calc(33.33% - 11px);

			@include each(2) {
				margin-right: $app-gutter-width;
			}
			@include each(3) {
				margin-right: 0;
			}
		}
	}

	&[data-layout="2"] {
		.c-listing__item {
			img {
				opacity: 1;
			}
			&:hover {
				img {
					opacity: 0.2;
				}
				.c-listing__info {
					opacity: 1;
				}
			}

		}

		.c-listing__info {
			position: absolute;
			bottom: 16px;
			left: 16px;
			opacity: 0;
		}
	}

	&__info {
		padding: 8px;
	}

	&__title {
		margin-bottom: 0;
	}

	&__overlay {
		display: none;
	}
}
