.c-modal {
	display: none;

	&--show {display:block;}
	&--load {display: none;}

	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 50;
		background: rgba(0, 0, 0, 0.6);
	}

	&__modal {
		display: block;
		width: 98%;
		max-width: 800px;
		height: 500px;
		max-height: 100%;
		padding: 40px;
		position: fixed;
		z-index: 100;
		left: 50%;
		top: 50%;
		overflow: auto;
		transform: translate(-50%, -50%);
		background: white;
		box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.9);
	}

	&__close {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		border: 0;
		background: black;
		color: white;
		padding: 8px 16px;
		font-size: 1.3rem;
		cursor: pointer;

		&:hover {
			background: $color-primary;
		}
	}
}
