
$emotion-overlay-color: #fff !default;
$emotion-overlay-background: rgba(35,185,217,0.5) !default;
$emotion-overlay-background-mobile: rgba(35,185,217,1) !default;
$emotion-font-headline: 64px !default;
$emotion-font-subline: $type-mid !default;

.c-emotion {
	padding-left: $app-gutter-width;
	padding-right: $app-gutter-width;
	margin-bottom: $element-margin-bottom-mobile;

	@include media('>grid') {
		margin-bottom: $element-margin-bottom;
	}

	@include media('>desktop') {
		padding-left: $app-gutter-width * 4;
		padding-right: $app-gutter-width * 4;
	}

	&__wrap {
		position: relative;
		height: 100%;
	}

	&__image {
		width: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}

	&__overlay {
		color: $emotion-overlay-color;
	}

	&__subline {
		display: inline-block;
		margin-bottom: 12px;
		padding-top: 12px;
		padding-bottom: 12px;
		@include fluid-type(font-size, 320px, 1366px, 20px, $emotion-font-subline);
		line-height: $line-height-headings;
	}

	&__headline {
		@include fluid-type(font-size, 320px, 1366px, 32px, $emotion-font-headline);
		font-family: $font-family-headings;
		font-weight: $font-weight-headings;
		line-height: $line-height-headings;
		text-rendering: optimizelegibility; // Fix the character spacing for headings
		padding-top: 10px;
		padding-bottom: 10px;
	}

	&__button {
		margin-top: 12px;
	}

	&--layout0,
	&--layout1 {

		.c-emotion__overlay {
			@include media('>desktop') {
				position: absolute;
				bottom: $app-gutter-width * 3;
				max-width: 50%;
				padding-left: $app-gutter-width * 4;
			}

			&-line {
				background: $emotion-overlay-background-mobile;
				padding-left: $app-gutter-width;
				padding-right: $app-gutter-width;

				@include media('>desktop') {
					background: $emotion-overlay-background;
					padding-left: $app-gutter-width * 2;
					padding-right: $app-gutter-width * 2;
				}
			}
		}
		.c-emotion__button {

			@include media('>desktop') {
				position: absolute;
				right: -90%;
				bottom: 0;
			}
		}
	}

	&--layout2 {
		.c-emotion__overlay {
			background: $emotion-overlay-background;
			text-align: center;
			padding-top: $app-gutter-width;
			padding-bottom: $app-gutter-width;
			padding-left: $app-gutter-width * 2;
			padding-right: $app-gutter-width * 2;

			@include media('>desktop') {
				@include horizontal-align(absolute);
				bottom: -10%;
				max-width: 50%;
			}
		}
	}

	&--layout3 {
		padding-bottom: $app-gutter-width;
		margin-bottom: $element-margin-bottom-mobile - $app-gutter-width;

		@include media('>grid') {
			margin-bottom: $app-gutter-width * 2;
		}

		@include media('>desktop') {
			padding-bottom: $app-gutter-width * 4;
		}

		@media (orientation: portrait) {
			height: 60vh !important;
		}

		.c-emotion__image {
			height: 100%;
		}

		.c-emotion__overlay {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background: $emotion-overlay-background;
			padding: $app-gutter-width;

			&-inner {
				@include vertical-align();
				text-align: center;
			}
		}

		.c-emotion__more {
			@include media('>grid') {
				display: block;
			}
		}
	}

	&__more {
		position: absolute;
		bottom: 32px;
		left: 50%;
		transform: translateX(-50%);
		background: url("../Images/icons/read-more.svg") center 0 no-repeat;
		background-size: auto 40%;
		padding-top: 25px;
		color: #fff;
		text-align: center;
		cursor: pointer;
		text-shadow: 2px 1px 0 rgba(0, 0, 0, 0.5);
		display: none;
	}
}
