$imagetextcolor-background: color('primary', 'light') !default;
$imagetextcolor-color: $color-text !default;
$imagetextcolor-color-mobile: $color-text !default;
$imagetextcolor-color-h2: $color-primary !default;

.c-imagetextcolor {
	position: relative;
	margin-bottom: $element-margin-bottom-mobile;
	padding-top: $app-gutter-width * 3;
	padding-bottom: $app-gutter-width * 3;

	@include media('>desktop') {
		display: flex;
		margin-bottom: $element-margin-bottom;
		padding-top: $app-gutter-width * 6;
		padding-bottom: $app-gutter-width * 6;
	}

	&--reverse {
		flex-direction: row-reverse;

		.c-imagetextcolor__color {
			left: 0;
		}
	}

	&__image {
		margin-bottom: $app-gutter-width * 2;

		@include media('>desktop') {
			flex: 1 1 auto;
			width: 55%;
			z-index: 10;
			margin-bottom: 0;
		}
	}
	&__text {
		padding-left: $app-gutter-width;
		padding-right: $app-gutter-width;
		color: $imagetextcolor-color-mobile;

		h2 {
			color: $imagetextcolor-color-h2;
		}

		@include media('>desktop') {
			flex: 1 1 auto;
			width: 45%;
			z-index: 10;
			padding-left: $app-gutter-width * 4;
			padding-right: $app-gutter-width * 4;
			color: $imagetextcolor-color;
		}
	}
	&__color {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 0;
		width: 60%;
		height: 100%;
		background: $imagetextcolor-background;
		display: none;

		@include media('>desktop') {
			display: block;
		}
	}

}
