
$teaserBoxes-teaser-fontsize: $type-mid !default;
$teaserBoxes-title-fontsize-min: $type-mid !default;
$teaserBoxes-title-fontsize-max: $type-large !default;

.c-teaserBoxes {
	@extend .o-wrapper-inner;

	&__intro {
		@include fluid-type(font-size, 320px, 1366px, 20px, $teaserBoxes-teaser-fontsize);
		line-height: 1.6;
		margin-bottom: $app-gutter-width * 3;
	}

	&__wrap {
		@include media('>grid') {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__item {
		margin-bottom: $element-margin-bottom-mobile;

		@include media('>grid') {
			flex: 1 1 auto;
			max-width: calc(50% - 20px);
			margin-right: 40px;
			margin-bottom: $element-margin-bottom;

			@include each(2) {
				margin-right: 0;
			}
		}

		&-title {
			@include fluid-type(font-size, 320px, 1366px, $teaserBoxes-title-fontsize-min, $teaserBoxes-title-fontsize-max);
		}
	}
}
