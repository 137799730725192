$news-teaser-fontwidth: $type-mid !default;

.c-news {
	@extend .o-wrapper-inner;

	&__wrap {
		@include media('>grid') {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__item {
		margin-bottom: $element-margin-bottom-mobile;

		@include media('>grid') {
			flex: 1 1 auto;
			max-width: calc(50% - 20px);
			margin-right: 40px;
			margin-bottom: $element-margin-bottom;

			@include each(2) {
				margin-right: 0;
			}
		}


	}
}
