
$teaserText-fontwidth: $type-mid !default;

.c-teaserText {
	@extend .o-wrapper-inner;
	@include fluid-type(font-size, 320px, 1366px, 20px, $teaserText-fontwidth);
	line-height: 1.6;
	margin-bottom: $element-margin-bottom-mobile;

	@include media('>grid') {
		margin-bottom: $element-margin-bottom;
	}
}
