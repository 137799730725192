
$footer-color-base: $color-primary !default;
$footer-color-meta: color('primary', 'dark') !default;
$footer-color-font: #fff !default;
$footer-title-size: 36px !default;

.c-footer {
	background: $footer-color-base;
	color: $footer-color-font;
	padding-top: $app-gutter-width * 3;

	&--dark {
		background: $footer-color-meta;
		padding: $app-gutter-width 0;
	}

	a:link, a:visited {
		color: $footer-color-font;
	}
	a:hover {
		text-decoration: underline;
	}

	&__title {
		$margin-desk: $app-gutter-width * 5;
		$margin-mobile: $app-gutter-width * 3;
		@include fluid-type(font-size, 320px, 1366px, $type-mid, $footer-title-size);
		@include fluid-type(padding-bottom, 320px, 1366px, $margin-mobile, $margin-desk);
		font-family: $font-family-headings;
		font-weight: $font-weight-headings;
		line-height: $line-height-headings;
		text-rendering: optimizelegibility;
	}

	&__row {
		@include media('>grid') {
			display: flex;
		}
	}

	&__col {
		flex: 1 1 span(4);
		margin-right: gutter();

		&:last-child {
			margin-right: 0;
		}
	}

	&__social {
		flex: 1 1 span(6);
		margin-bottom: $app-gutter-width * 2;

		@include media('>grid') {
			margin-bottom: 0;
		}
	}
	&__meta {
		flex: 1 1 span(6);

		line-height: 40px;

		@include media('>grid') {
			text-align: right;
		}

		a {
			margin-right: 16px;
		}
	}
}
