//------------------------------------//
//  $VARIABLES
//------------------------------------//


// Fonts

// 'Montserrat', 'Merriweather', 'Source Serif Pro', 'Cormorant', 'Catamaran', 'PT Serif', 'Noto Sans', 'Roboto', 'Source Sans Pro', 'Lato', 'Ubuntu', 'Open Sans', 'PT Sans', 'Poppins', 'Fira Sans', 'Raleway'
$font-primary: "Montserrat" !default;
$font-secondary: "" !default;
$font-type-primary: sans-serif !default;
$font-type-secondary:  sans-serif !default;

@if ($font-secondary == "") {
	$font-secondary: $font-primary;
}

$font-family-primary: '#{$font-primary}', $font-type-primary;
$font-family-secondary: '#{$font-secondary}', $font-type-secondary;

// Base
$base-font-size					: 16px !default;
$base-line-height				: 32px !default;
$base-font-size--mobile			: $base-font-size;
$base-font-weight				: 300 !default;
$base-font-weight-bold			: 600 !default;

$base-font-family             	: $font-family-primary;
$font-family-headings         	: $font-family-secondary;
$font-weight-headings         	: 400 !default;
$line-height-headings         	: 1.35;

$type-largest					: 56px !default;
$type-large						: 32px !default;
$type-mid						: 24px !default;
$type-base						: $base-font-size !default;
$type-small						: 14px !default;

// Calculated measurements
$line-height-ratio: $base-line-height / $base-font-size;
$base-spacing-unit: $line-height-ratio;
$half-spacing-unit: $base-spacing-unit / 2;
