.e-list {
	margin-left: 0;

	li {
		list-style: none;
		background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4LjA2IDE0Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6bm9uZTtzdHJva2U6IzMzMztzdHJva2UtbWl0ZXJsaW1pdDoxMDtzdHJva2Utd2lkdGg6MS41cHg7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5iYy1idWxsZXQ8L3RpdGxlPjxnIGlkPSJFYmVuZV8yIiBkYXRhLW5hbWU9IkViZW5lIDIiPjxnIGlkPSJFYmVuZV8xLTIiIGRhdGEtbmFtZT0iRWJlbmUgMSI+PHBvbHlsaW5lIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIwLjUzIDEzLjQ3IDcgNyAwLjU0IDAuNTMiLz48L2c+PC9nPjwvc3ZnPg==') no-repeat;
		background-size: 0.4em;
		background-position: top 7px left;
		padding-left: 25px;
	}
}

.e-list222222 {
	padding-left: 0;
	margin-bottom: 32px;

	li {
		background-size: 8px;
	}
}
