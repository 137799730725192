
$gallery-overlay-color: #fff !default;
$gallery-overlay-background: rgba(35,185,217,0.5) !default;
$gallery-overlay-background-hover: rgba(35,185,217,1) !default;

.c-gallery {
	@extend .o-wrapper-inner;
	margin-bottom: $element-margin-bottom-mobile;

	@include media('>grid') {
		margin-bottom: $element-margin-bottom;
	}

	&--multi {
		margin-bottom: $element-margin-bottom-mobile - 24px;

		@include media('>grid') {
			margin-bottom: $element-margin-bottom - 24px;
		}
	}

	&__intro {
		@include fluid-type(font-size, 320px, 1366px, 20px, $teaserBoxes-teaser-fontsize);
		line-height: 1.6;
		margin-bottom: $app-gutter-width * 3;
	}

	&__cols {
		column-gap: 20px;

		&[data-columns='2'] {
			column-count: 2;
		}

		&[data-columns='3'] {
			column-count: 2;

			@include media('>grid') {
				column-count: 3;
			}
		}

		&[data-columns='4'] {
			column-count: 2;

			@include media('>grid') {
				column-count: 4;
			}
		}

		&[data-columns='5'] {
			column-count: 3;

			@include media('>grid') {
				column-count: 5;
			}
		}
	}

	&__item {
		margin-bottom: 20px;
		width: 100%;
	}

	&__wrap {
		display: flex;
		flex-wrap: wrap;
	}

	&__gallery {
		position: relative;
		margin-bottom: 24px;

		@include media('>grid') {
			flex: 1 1 auto;
			max-width: calc(50% - 12px);
			margin-right: 24px;

			@include each(2) {
				margin-right: 0;
			}
		}

		@include media('>tablet') {
			flex: 1 1 auto;
			max-width: calc(33.33% - 16px);
			margin-right: 24px;

			@include each(2) {
				margin-right: 24px;
			}
			@include each(3) {
				margin-right: 0;
			}
		}

		&-title {
			position: absolute;
			bottom: 0;
			z-index: 20;
			width: 100%;
			padding: 0 $app-gutter-width;
			color: $gallery-overlay-color;
			background: $gallery-overlay-background;
		}

		&:hover .c-gallery__gallery-title {
			background: $gallery-overlay-background-hover;
		}
	}
}
