/**************************************/
/* Default styles for news extension */
/**************************************/
.news .clear {
	clear: both;
}

.news .nav ul {
	float: left;
	display: inline;
	margin: 0;
}

.news .nav li {
	float: left;
}

.news .nav a {
	float: left;
	display: block;
}

.news .nav:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.news .article {
	padding-bottom: 15px;
	margin-bottom: 15px;
}

.news .article:last-child {
	padding-bottom: 0;
	margin-bottom: 0;
}

.news .article:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.news .article .news-img-wrap {
	float: left;
	margin: 3px 12px 5px 0;
	background: #fff;
}

.news .article .news-img-wrap a {
	display: block;
	float: left;
	padding: 4px;
	border: 1px solid #ddd;
	background: #fff;
}

.news .article .news-img-wrap img {
	float: left;
	height: auto;
	width: auto;
}

.news .article .teaser-text {
	margin: 0 0 10px 0;
}

.news .article h3 {
	margin-bottom: 0;
}

.news .footer {
	clear: both;
	padding: 10px 0 0 0;
	border-top: 1px solid #eee;
	margin-top: 15px;
	color: #888;
}

.news .footer p {
	line-height: 1em;
}

.news .footer span {
	display: inline-block;
	padding-right: 8px;
	margin-right: 8px;
	border-right: 1px solid #ddd;
}

.news .footer span:last-child {
	border-right: 0;
}

.news .footer .news-category a {
	text-transform: uppercase;
}

.news .no-news-found {
	color: #DB0202;
	font-style: italic;
}

.news .page-navigation p {
	float: left;
}

.news .page-navigation ul {
	float: right;
}

.news .page-navigation ul li {
	float: left;
	display: block;
	padding: 0 2px;
	background: none;
}

.news .page-navigation ul li a {
	display: block;
}

/* category menu view */
.news-category-view ul li {
	padding: 5px 8px;
}

.news-category-view ul li li {
	padding: 2px 8px 2px 12px;
}

.news-category-view > ul > li:nth-child(even) {
	background: #f9f9f9;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
}

.news-category-view .category-desc {
	padding-left: 10px;
	margin-left: 10px;
	border-left: 1px solid #ddd;
}

/* single view */
.news-single .footer {
	margin-bottom: 20px;
	margin-top: 5px;
	padding: 10px 0 0 0;
	border-bottom: 0;
	border-top: 1px solid #eee;
}

.news-single .article .news-img-wrap {
	float: right;
	width: 282px;
	margin: 0 0 0 25px;
}

.news-single .article .news-img-wrap img {
	float: none;
}

.news-single .teaser-text {
	font-size: 15px;
	color: #888;
}

.news-text-wrap {
	margin-bottom: 10px;
}

.news-img-caption {
	color: #888;
}

/* related news + files */
.news-related-wrap {
	display: table;
	margin-top: 20px;
	width: 100%;
}

.news-related {
	width: 50%;
	padding: 15px;
	border: 1px solid #eee;
	display: table-cell;
}

.news-related ul li {
	line-height: 1em;
	margin-bottom: 12px;
}

.news-related ul li span {
	border-right: 1px solid #DDDDDD;
	display: inline-block;
	margin-right: 8px;
	padding-right: 8px;
	color: #888;
}

.news-related ul li span:last-child {
	border-right: 0;
	padding-right: 0;
}

.news-related h4 {
	margin-bottom: 10px;
}

.news-related-files ul li {
	margin-bottom: 20px;
}

.news-related-files .news-related-files-link .jpg {
	background-position: 0 0;
}

.news-clear {
	clear: both;
}
