
$logo-width: 200px !default;
$logo-width-desktop: 200px !default;
$logo-overlay: false !default;
$logo-overlay-background: #fff !default;
$logo-overlay-padding: 8px !default;
$logo-background: false !default;

$meta-fontsize: 16px !default;
$l10n-color: $color-text !default;
$l10n-color-hover: $color-primary !default;

.c-header {
	@extend .o-wrapper-inner;
	position: relative;

	@include media('>900px') {
		display: flex;
	}

	@if $logo-overlay == true {
		min-height: 80px;
	}


	&__logo {
		width: $logo-width;
		padding: 16px 0;
		transition: all 0.5s ease;

		@if $logo-overlay == true {
			position: absolute;
			z-index: 30;
			background: $logo-overlay-background;
			padding: $logo-overlay-padding;
		}

		@if $logo-background == true {
			background: $logo-overlay-background;
			padding: $logo-overlay-padding;
		}

		@include media('>900px') {
			width: $logo-width-desktop;
			flex: 0 1 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;

			@if $logo-overlay == false {
				padding: 0;
			}
			@if $logo-background == true {
				padding: $logo-overlay-padding;
			}
		}

	}

	&__meta {
		font-size: $meta-fontsize;

		@include media('>900px') {
			min-height: 36px;
			padding: 4px 0 0;
		}
	}

	&__l10n {
		a:link, a:visited {
			color: $l10n-color;
			margin-left: 10px;
		}
		a:hover {
			color: $l10n-color-hover;
		}

		&--active {
			text-decoration: underline;
		}
	}

	&__nav {

		@include media('>900px') {
			flex: 1 1 auto;
			text-align: right;
		}

		&-toggle {
			position: absolute;
			top: 30%;
			right: 10%;
			z-index: 999;
			cursor: pointer;

			@include media('>900px') {
				display: none;
			}
		}
	}
}
