
$button-color-background	: $color-primary !default;
$button-color-border		: $color-primary !default;
$button-color-font			: #fff !default;
$button-color-background-hover	: $color-text !default;
$button-color-border-hover		: $color-text !default;
$button-color-font-hover		: #fff !default;

.c-button {
	display: inline-block;
	text-align: center;
	padding: 8px 24px;
	min-width: 120px;
	background: $button-color-background;
	border: 1px solid $button-color-border;

	&--small {
		padding: 4px 12px;
	}

	&:link, &:visited {
		color: $button-color-font;
	}
	&:hover {
		background: $button-color-background-hover;
		border: 1px solid $button-color-border-hover;
		color: $button-color-font-hover;
	}

	&__wrap {
		@extend .o-wrapper-inner;
		margin-bottom: $element-margin-bottom;
	}

	&--margin {
		margin-top: 15px;
	}

	&--white {
		background: transparent;
		border: 1px solid #fff;

		&:link, &:visited {
			color: #fff;
		}
		&:hover {
			background: #fff;
			color: $color-primary;
		}
	}

}
