/**
 * Global Variables first included so they can easily get overriden.
 *
 * @package  phoenixBase
 * @author   Rene Kreupl <info@renekreupl.de>
 */

/*
|--------------------------------------------------------------------------
| _settings.global.scss
|--------------------------------------------------------------------------
*/

$wrapper-outer:		1440px !default;
$wrapper-inner:		1132px !default;
$app-gutter-width:	16px !default;

$element-margin-bottom: $app-gutter-width * 6 !default;
$element-margin-bottom-mobile: $app-gutter-width * 3 !default;

// Master Breakpoints
$breakpoints: (
	'phone': 320px,
	'grid': 640px,
	'tablet': 768px,
	'desktop': 980px,
	'wrapper-inner': 1132px,
	'wrapper': 1440px
);

// 4 symmetrical, fluid columns
// gutters are 1/4 the size of a column
// elements span 1 less gutter than columns
// containers span 1 less gutter as well
$susy: (
	'columns': susy-repeat(12),
	'gutters': 0.25,
	'spread': 'narrow',
	'container-spread': 'narrow',
);


/**
 * Extend `.btn {}` in _components.buttons.scss.
 */

$base-spacing-unit:       $base-line-height;
$base-spacing-unit-tiny:  round($base-spacing-unit / 4);
$base-spacing-unit-small: round($base-spacing-unit / 2);
$base-spacing-unit-large: round($base-spacing-unit * 2);
$base-spacing-unit-huge:  round($base-spacing-unit * 4);
