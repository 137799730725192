/**
 * Global Color palette
 */

// Generic colors

$color-key: 'base' !default;

$colors: (
	'primary': (
		'base': #007cbb,
		'light': #cce5f1,
		'dark': #005c8b
	),
	'secondary': (
		'base': #ff7d00,
		'dark': #bd5d00
	),
	'foreground': (
		'base': #191919,
		'light': #333333,
		'dark': #111111,
		'darker': #000000
	),
	'background': (
		'base': #e9e9e9,
		'light': #ffffff,
		'dark': #dddddd
	),
	'grey': (
		'base': #d98328,
		'light': #eeeeee,
		'dark': #2d2c31
	),
);

// Color Scheme
$color-primary                	: color('primary') !default;
$color-secondary              	: color('secondary');
$color-highlight              	: $color-primary;

// Common colors
$color-text                   	: #353535 !default;
$color-bg                     	: #fafafa !default;
$color-wrapper-bg				: #fff !default;
$color-wrapper-border			: #dbdbdb !default;
$color-border                 	: #ddd;

// Links
$color-link-default				: $color-highlight;
$color-link-visited				: $color-link-default;
$color-link-hover				: $color-text;
$color-link-active				: $color-text;

// Text Selection
$color-selection				: #fff;
$color-selection-bg				: $color-primary;

// <hr> border color
$hr-border						: lighten(#000, 70%);


// 3rd party brand colors - find more at brandcolors.net
$bc-youtube						: #cd201f;
$bc-pinterest					: #bd081c;
$bc-facebook					: #3b5998;
$bc-instagram					: #c13584;
$bc-twitter						: #1da1f2;
$bc-xing						: #026466;
$bc-linkedin					: #0077b5;


// Form validation colors
$state-success					: #468847;
$state-error					: #b94a48;
$form-border-color-focus		: $color-primary;
$form-submit-color				: $color-primary;
$form-submit-color-hover		: #444;
