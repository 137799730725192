// =========================================================================
//
//  PRECISE CONTROL OVER RESPONSIVE TYPOGRAPHY FOR SASS
//  ---------------------------------------------------
//  Indrek Paas @indrekpaas
//
//  Inspired by Mike Riethmuller's Precise control over responsive typography
//  http://madebymike.com.au/writing/precise-control-responsive-typography/
//
//  `strip-unit()` function by Hugo Giraudel
//
//  11.08.2016 Remove redundant `&` self-reference
//  31.03.2016 Remove redundant parenthesis from output
//  02.10.2015 Add support for multiple properties
//  24.04.2015 Initial release
//
// =========================================================================

/* Single property */
/*
html {
	@include fluid-type(font-size, 320px, 1366px, 14px, 18px);
}
*/

/* Multiple properties with same values */
/*
h1 {
	@include fluid-type(padding-bottom padding-top, 20em, 70em, 2em, 4em);
}
*/

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
	@each $property in $properties {
		#{$property}: $min-value;
	}

	@include media('>#{$min-vw}') {
		@each $property in $properties {
			#{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
		}
	}

	@include media('>#{$max-vw}') {
		@each $property in $properties {
			#{$property}: $max-value;
		}
	}
}
